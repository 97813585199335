import ourwork from "../Assets/ourwork.png";
import work1 from "../Assets/Work/1.png";
import work2 from "../Assets/Work/2.png";
import work3 from "../Assets/Work/3.png";
import work4 from "../Assets/Work/4.png";
import work5 from "../Assets/Work/5.png";
import work6 from "../Assets/Work/6.png";
import work7 from "../Assets/Work/7.png";
import work8 from "../Assets/Work/8.png";
import work9 from "../Assets/Work/9.png";
import work10 from "../Assets/Work/10.png";
import work11 from "../Assets/Work/11.png";
import work12 from "../Assets/Work/12.png";
import instagram from "../Assets/Footer/instagram.svg";
import React from "react";

const OurWork = () => {
  return (
    <>
      <div
        data-aos="fade-in"
        data-aos-duration="1500"
        className="ourwork-container wrapper-small"
      >
        <div>
          <h1>Nuestro trabajo</h1>
          <h2>
            Disruptivo, humano <br /> y profesional.
          </h2>
          <p>
            Potenciamos grupos de trabajo a través de acciones de wellness con
            espíritu, esperiencias de calidad, ¡y mucha onda!
          </p>
          <a
            className="our-work-social"
            target="_blank"
            href="https://www.instagram.com/focus.wellnesscorp/"
          >
            <img src={instagram} alt="" />
            Seguinos en instagram
          </a>
        </div>
        <div className="our-workimg-cont">
          <img className="ourwork-img" src={ourwork} alt="" />
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="ourwork-images wrapper"
      >
        <img src={work1} alt="" />
        <img src={work2} alt="" />

        <img src={work3} alt="" />
        <img src={work4} alt="" />

        <img src={work5} alt="" />
        <img src={work6} alt="" />

        <img src={work7} alt="" />
        <img src={work8} alt="" />

        <img src={work9} alt="" />
        <img src={work10} alt="" />

        <img src={work11} alt="" />
        <img src={work12} alt="" />
      </div>
    </>
  );
};

export default OurWork;
