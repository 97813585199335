import Home from "./Components/Home";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CalendlyWindow from "./Components/CalendlyWindow";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/calendly" element={<CalendlyWindow />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
