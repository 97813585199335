const NavBar = ({ open, setOpen, isNewPage }) => {
  const closeMenu = () => setOpen(false);
  return (
    <div className={`nav-bar ${open ? "nav-bar-open" : ""}`}>
      <nav>
        <ul>
          <li className="links-details">
            <a
              onClick={closeMenu}
              href={isNewPage ? "/#services-scroll" : "#services-scroll"}
              className="nav-link"
            >
              Servicios
            </a>
            <span>Servicios</span>
          </li>
          <li className="links-details">
            <a
              onClick={closeMenu}
              href={isNewPage ? "/#method-sroll" : "#method-scroll"}
              className="nav-link"
            >
              {" "}
              Nuestro Método
            </a>
            <span>Nuestro Método</span>
          </li>
          <li className="links-details">
            <a
              onClick={closeMenu}
              href={isNewPage ? "/#teamwork-scroll" : "#teamwork-scroll"}
              className="nav-link"
            >
              {" "}
              Nuestro Equipo
            </a>
            <span>Nuestro Equipo</span>
          </li>
          <li className="links-details">
            <a
              onClick={closeMenu}
              href={isNewPage ? "/#benefits-scroll" : "#benefits-scroll"}
              className="nav-link"
            >
              {" "}
              Beneficios
            </a>
            <span>Beneficios</span>
          </li>
        </ul>{" "}
        <a
          className="contact-header-responsive"
          href="https://api.whatsapp.com/send?phone=5491127479516"
          target="_blank"
        >
          Contactanos
        </a>
      </nav>
    </div>
  );
};

export default NavBar;
