import React from "react";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { objectToFormData } from "../utils";
import axios from "axios";

const Contact = () => {
  const [recaptchavalue, setRecaptchaValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const recaptchaRef = useRef();
  const [fields, setFields] = useState({
    name: "",
    company: "",
    email: "",
  });

  const handleChangeField = (field, value) => {
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const submitDisabled = () => {
    if (loading) return true;

    const formFields = Object.keys(fields);
    let empty = false;

    for (let field of formFields) {
      const value = fields[field].trim();
      if (value === "") {
        empty = true;
        break;
      }
    }

    return empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await axios({
        method: "POST",
        url: `https://focuswellnesscorp.com/api/mail.php`,
        data: objectToFormData({
          nombre: fields.name,
          empresa: fields.company,
          email: fields.email,

          "g-recaptcha-response": recaptchavalue,
        }),
      });

      if (response.data.status !== "ok") throw new Error();

      await Swal.fire({
        title: "Mensaje enviado!",
        text: "Se ha enviado correctamente!",
        icon: "succes",
      });

      setFields({
        name: "",
        empresa: "",
        email: "",
      });

      setRecaptchaValue("");
      recaptchaRef.current?.reset();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Lo sentimos, se ha producido un error, intente nuevamente.");
    }
  };

  return (
    <div className="input-section">
      <p className="input-title">
        Recibí noticias y consejos de salud útiles, <br />
        recomendados por profesionales de la salud.
      </p>
      <form onSubmit={handleSubmit}>
        <input
          value={fields.name}
          className="first-input"
          type="text"
          placeholder="Nombre y apellido"
          onChange={(e) => handleChangeField("name", e.target.value)}
        />
        <input
          value={fields.company}
          className="first-input"
          type="text"
          placeholder="Empresa"
          onChange={(e) => handleChangeField("company", e.target.value)}
        />
        <input
          value={fields.email}
          className="second-input"
          type="text"
          placeholder="Email"
          onChange={(e) => handleChangeField("email", e.target.value)}
        />
        <div className="send-info">
          {error && <p>{error}</p>}{" "}
          <div className="recaptcha-container">
            <ReCAPTCHA
              className="recaptcha"
              sitekey="6LfcLeUpAAAAAJc-m8x8vz8PSyT1CmXjQORadO48"
              onChange={setRecaptchaValue}
              ref={recaptchaRef}
            />
          </div>
          <button className="send-footer-button" disabled={submitDisabled()}>
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
