import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import CalendlySection from "./CalendlySection";
import { Link } from "react-router-dom";
import CarrouselBrandComplementary from "./CarrouselBrandComplementary";
import arrowleft from "../Assets/arrow left.svg";
import ScrollToTop from "./ScrollToTop";
import whatsapp from "../Assets/sticky-wp.png";

const CalendlyWindow = () => {
  return (
    <div>
      <ScrollToTop />

      <div className="cadendly-window-container">
        <div className="whatsapp-opener">
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5491127479516"
          >
            {" "}
            <img src={whatsapp} alt="" />
          </a>
        </div>
        <Header isNewPage={true} />
        <div className="cadendly-details">
          <div className="wrapper">
            <Link className="goingback-link" to="/">
              <img src={arrowleft} alt="" />
              Volver
            </Link>
          </div>

          <CalendlySection />
        </div>
      </div>

      <div className="brand-complementary-container">
        <h1 className="wrapper">Empresas líderes confían en FOCUS</h1>
        <CarrouselBrandComplementary />
      </div>

      <Footer />
    </div>
  );
};

export default CalendlyWindow;
