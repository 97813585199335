import PerformanceVector from "../Assets/performance-vector.svg";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
const Performance = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div className="wrapper-mobile">
      <div data-aos="fade-left" className="performance-container wrapper">
        <div className="details-performance">
          <h1>
            Potenciamos el{" "}
            <span className="performance-text">
              <i>rendimiento</i> <img src={PerformanceVector} alt="" />
            </span>{" "}
            de tu empresa <br />
            mejorando la salud de los colaboradores.
          </h1>
          <h2>Convertite en una empresa saludable con nuestro método.</h2>
          <Link to="/calendly">Agendar una reunión</Link>
        </div>
      </div>
    </div>
  );
};

export default Performance;
