import benefitsimg from "../Assets/benefits.svg";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./ScrollToTop";

const Benefits = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-out",
    });
  }, []);
  const benefitsCards = [
    {
      title: "Profesionalismo",
      description: "Somos profesionales de la salud.",
    },
    {
      title: "Mensurabilidad",
      description: "Lo que no se mide no se mejora.",
    },
    {
      title: "Adaptabilidad",
      description: "Nos adaptamos a diversos formatos de trabajo.",
    },
    {
      title: "Experiencia",
      description: "Profesionales con experiencia y recursos pedagógicos.",
    },
    {
      title: "Vitalidad",
      description: "Una pausa disfrutable, no una interrupción.",
    },
    {
      title: "Flexibilidad",
      description: "Horarios laborales y extra laborales.",
    },
    {
      title: "Adherencia",
      description: "Comunicación que se distingue.",
    },
    {
      title: "Comunidad",
      description: "Generamos grupo y conectividad.",
    },
  ];
  return (
    <>
      <ScrollToTop />
      <div data-aos="fade-rigth" className="benefits-container wrapper-small">
        <h1 className="benefits-title">¿Por qué elegirnos?</h1>
        <div className="benefits-details-container">
          {benefitsCards.map((item) => (
            <div className="benefits-details-info">
              <div className="benefits-responsive">
                <img src={benefitsimg} alt="" />
                <div>
                  <h1>{item.title}</h1>
                  <h2>{item.description}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Benefits;
