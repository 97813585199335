import actions1 from "../Assets/ActionsSlider/1.svg";
import actions2 from "../Assets/ActionsSlider/2.svg";
import actions3 from "../Assets/ActionsSlider/3.svg";
import actions4 from "../Assets/ActionsSlider/4.svg";
import actions5 from "../Assets/ActionsSlider/5.svg";
import actions6 from "../Assets/ActionsSlider/6.svg";
import actions7 from "../Assets/ActionsSlider/7.svg";
import actions8 from "../Assets/ActionsSlider/8.svg";
import actions9 from "../Assets/ActionsSlider/9.svg";
import leftarrow from "../Assets/left-green.svg";
import rightarrow from "../Assets/right-green.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const CardSliderActiones = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-out",
    });
  }, []);
  let sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const cardsActions = [
    {
      image: actions1,
      title: "Bienestar financiero",
    },
    {
      image: actions2,
      title: "Ergokit",
    },
    {
      image: actions3,
      title: "Consultas personalizadas",
    },
    {
      image: actions4,
      title: "Integración de equipos de salud",
    },
    {
      image: actions5,
      title: "Embarazo y lactancia",
    },
    {
      image: actions6,
      title: "Descuentos y beneficios con nuestro equipo",
    },
    {
      image: actions7,
      title: "Semana de bienestar",
    },
    {
      image: actions8,
      title: "Talleres de primeros auxilios",
    },
    {
      image: actions9,
      title: "Semana de salud mental",
    },
  ];

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <div data-aos="fade-right" className="card-slider-container wrapper">
      <div className="wrapper control-card-container">
        <h1>Otras acciones que potencian</h1>
        <div>
          <button onClick={previous}>
            {" "}
            <img src={leftarrow} alt="" />
          </button>

          <button onClick={next}>
            {" "}
            <img src={rightarrow} alt="" />
          </button>
        </div>
      </div>
      <div className="cards-slider-details">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {cardsActions.map((item) => (
            <div>
              <div className="cards-actions-info">
                {" "}
                <img src={item.image} alt="" />
                <p>{item.title}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CardSliderActiones;
