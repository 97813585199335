import equipo1 from "../Assets/Equipo/1.png";
import equipo2 from "../Assets/Equipo/2.png";
import equipo2ig from "../Assets/Footer/instagram.svg";
import equipo2in from "../Assets/Footer/linkedin.svg";
import equipo3 from "../Assets/Equipo/3.png";
import equipo4 from "../Assets/Equipo/4.png";
import equipo5 from "../Assets/Equipo/5.png";
import React from "react";
import ScrollToTop from "./ScrollToTop";

const TeamWork = () => {
  const teamWork = [
    {
      data: "Camila Grela, Yoga",
      data2: "Coordinadora",
      image: equipo1,
    },
    {
      data: "Facundo Cámpora, Director",
      image: equipo2,
      image2: equipo2ig,
      image3: equipo2in,
    },
    {
      data: "Guadalupe Mora, Nutrición",
      data2: "Coordinadora",
      image: equipo3,
    },
    {
      data: "Rodrigo Mon, Kinesiología",
      data2: "Coordinador",
      image: equipo4,
    },
    {
      data: "Natalia Destéfano, Coordinación",
      image: equipo5,
    },
  ];
  return (
    <>
      {" "}
      <ScrollToTop />
      <div
        id="teamwork-scroll"
        data-aos="fade-in"
        data-aos-duration="1500"
        className="team-work-container"
      >
        {teamWork.map((item) => (
          <div className="team-work-rotation">
            {" "}
            <div>
              <img
                className={`team-work-person ${!!item.image3 ? "hide" : ""}`}
                src={item.image}
                alt=""
              />
              {!!item.image3 && (
                <a
                  href="https://www.instagram.com/lic.camporafacundo/"
                  target="_blank"
                  className="team-work-person-link"
                >
                  <img src={item.image} alt="" className="team-work-person" />
                </a>
              )}
            </div>
            <div className="team-work-details">
              <div>
                <p>{item.data}</p>
                <p className="team-work-details-info">{item.data2}</p>
              </div>

              {!!item.image3 && (
                <a
                  className="team-work-link"
                  href="https://www.linkedin.com/in/facundo-campora-117a11167/"
                  target="_blank"
                >
                  <img className="team-work-social" src={item.image3} alt="" />
                </a>
              )}
              {!!item.image2 && (
                <a
                  href="https://www.instagram.com/lic.camporafacundo/"
                  target="_blank"
                >
                  <img className="team-work-social" src={item.image2} alt="" />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
      <div data-aos="fade-in" className="team-work-info wrapper-small">
        <div className="team-description">
          <p className="team-title">Expertos en bienestar corporativo</p>
          <h1>Conoce a nuestro equipo</h1>
          <p className="info-team-title">
            Somos una red de profesionales de la salud comprometidos en promover
            el bienestar integral en todas sus dimensiones: física, emocional,
            nutricional y social. Creemos firmemente en que generar un
            equilibrio en estas áreas puede contribuir positivamente al mundo
            laboral y nos esforzamos cada día para educar y empoderar a más
            personas a descubrir y aprovechar todo su rendimiento.
          </p>
        </div>
        <div className="team-specialties">
          <p>
            Si buscas trabajar con horarios flexibles desde la comodidad de tu
            casa o de manera presencial, no dudes en contactarnos para conocerte
            y explorar posibilidades de colaboración!
          </p>
          <div className="team-specialties-list">
            <p>Estamos en busqueda de:</p>
            <ul>
              <li>Kinesiólogos</li>
              <li>Nutricionistas</li>
              <li>Instructores de yoga</li>
              <li>Coaches</li>
              <li>Profesores de Ed. Física</li>
              <li>Psicólogos</li>
              <li>Médico laboral</li>
            </ul>

            <a
              className="contact-specialties"
              href="https://api.whatsapp.com/send?phone=5491127479516"
              target="_blank"
            >
              Sumáte al equipo
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamWork;
