import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import rightarrow from "../../Assets/arrow-right.svg";
import SERVICES from "./content";
import ScrollToTop from "../ScrollToTop";
import ARROW_DOWN from "../../Assets/arrow-down.svg";

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 900,
      easing: "ease-in-out",
    });
  }, []);

  const [selected, setSelected] = useState(SERVICES[0].id);

  //const content = SERVICES.find((service) => service.id === selected).content;

  const content = [
    ...SERVICES[0].content,
    ...SERVICES[1].content,
    ...SERVICES[2].content,
    ...SERVICES[3].content,
  ];

  const handleSelect = (id) => {
    setSelected(id);
    setTimeout(() => {
      window.scrollBy(0, 1);
      window.scrollBy(0, -1);
    }, 1);
    setIsDropdownOpen(false);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  console.log({ selected, SERVICES });

  return (
    <>
      <ScrollToTop />
      <div data-aos="fade-in" className="services-container wrapper">
        <div id="services-scroll" className="services-container-scroll">
          {" "}
        </div>
        <h1 className="services-title">Nuestros servicios</h1>
        <h2>Todos los servicios en un solo proveedor</h2>
        <p>Navegá por las áreas de salud para ver cómo podemos ayudarte:</p>

        <div className="services-tabs">
          {SERVICES.map((service) => (
            <button
              key={service.id}
              className={selected === service.id ? "active" : ""}
              onClick={() => {
                handleSelect(service.id);
              }}
            >
              {service.label}
            </button>
          ))}
        </div>

        <div className="services-dropdown">
          <div className="services-dropdown-wrap">
            <button
              className={isDropdownOpen ? "open" : ""}
              onClick={() => setIsDropdownOpen((prev) => !prev)}
            >
              <span>{SERVICES.find((i) => i.id === selected).label}</span>
              <img src={ARROW_DOWN} alt="" />
            </button>
          </div>

          <div
            className={`services-dropdown-options ${
              isDropdownOpen ? "open" : ""
            }`}
          >
            {SERVICES.filter((i) => i.id !== selected).map((i) => (
              <button onClick={() => handleSelect(i.id)} key={i.id}>
                {i.label}
              </button>
            ))}
          </div>
        </div>

        <div className="services-cards wrapper">
          {content.map((item) => (
            <div
              data-aos="zoom-in"
              className={`services-cards-details ${
                item.categoryId !== selected ? "hidden" : ""
              }`}
            >
              <img className="services-img" src={item.image} alt="" />

              <div className="services-card-info">
                <h1>{item.title}</h1>
                <p>{item.description}</p>
                <a href="https://wa.me/message/QDQIHTRHDU6CG1" target="_blank">
                  Más información{" "}
                  <img src={rightarrow} alt="right arrow icon" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Services;
