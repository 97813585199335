import React from "react";

const WhoWeAre = () => {
  return (
    <div
      data-aos="fade-in"
      data-aos-duration="1000"
      className="who-we-are-container"
    >
      <div className="who-we-are wrapper-small">
        <div className="who-we-are-title">
          <p>Tu aliado en el bienestar corporativo</p>
          <h1>
            Garantizá <br /> resultados con <br /> profesionales <br />{" "}
            capacitados
          </h1>
        </div>
        <div className="who-we-are-text">
          <p>
            Somos una red de profesionales de la salud especializados en <br />
            brindar soluciones para el bienestar físico y mental en las
            empresas.
          </p>
          <p>
            {" "}
            Nuestro método tiene como objetivo principal generar acciones <br />
            customizadas para mejorar el rendimiento y productividad de los{" "}
            <br />
            equipos.
          </p>
          <p>
            {" "}
            A través de experiencias de wellness con propósito y contenidos de{" "}
            <br />
            calidad, educamos, fortalecemos y promovemos hábitos saludables{" "}
            <br /> en los espacios de trabajo.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
