import React from "react";
import director from "../Assets/director.png";
import instagram from "../Assets/Footer/instagram.svg";
import linkedin from "../Assets/Footer/linkedin.svg";

import { InlineWidget } from "react-calendly";

const CalendlySection = () => {
  return (
    <>
      <div className="cadendly-section-container wrapper-small">
        <div className="cadendly-section-info">
          <h1 className="cadendly-title">
            Te ayudamos a <br />
            convertirte en una <br />
            empresa saludable
          </h1>
          <h2>
            Diseñamos las propuestas que se adaptan mejor a tus necesidades, a
            las de los colaboradores y a tu presupuesto.
          </h2>
          <a href="">Agendá tu reunión ahora.</a>
          <div className="lic-info-container">
            <img
              className="director-img"
              src={director}
              alt="Fotografía Director FOCUS"
            />
            <div>
              <h1 className="lic-info">Lic. Cámpora Facundo</h1>
              <p>M.N: 14498</p>
              <p>Cofundador y director</p>

              <a
                href="https://www.linkedin.com/in/facundo-campora-117a11167/"
                target="_blank"
              >
                <img className="lic-info-social" src={linkedin} alt="" />
              </a>
              <a
                href="https://www.instagram.com/lic.camporafacundo/"
                target="_blank"
              >
                <img className="lic-info-social" src={instagram} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="box-calendly">
          <div className="details-box-calendly">
            <InlineWidget url="https://calendly.com/focuswellnesscorp/focuswellnesscorp" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendlySection;
