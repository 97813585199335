import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import combo1 from "../Assets/Combos/1.png";
import combo2 from "../Assets/Combos/2.png";
import combo3 from "../Assets/Combos/3.png";
import combo4 from "../Assets/Combos/4.png";
import leftarrow from "../Assets/left-white.svg";
import rightarrow from "../Assets/right-white.svg";
import { useRef } from "react";
import React from "react";

const Combo = () => {
  let sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    swipe: true,
    arrows: false,
  };

  const comboCards = [
    {
      image: combo2,
      color: "#96fbe6",
      font: "#004f60",
    },
    {
      image: combo1,
      color: "#96fbe6",
      font: "#004f60",
    },

    {
      image: combo3,
      color: "#004f60",
      font: "#96fbe6",
    },
    {
      image: combo4,
      color: "#96fbe6",
      font: "#004f60",
    },
  ];

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  return (
    <div
      data-aos="fade-in"
      data-aos-duration="1500"
      className="combo-container"
    >
      <div id="benefits-scroll" className="combo-container-scroll"></div>
      <div className="combo-container-info">
        <div className="arrow-combo previous-arrow-combo">
          <button onClick={previous}>
            {" "}
            <img src={leftarrow} alt="" />
          </button>
        </div>

        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {comboCards.map((item) => (
            <div className="combo-details">
              <div className="combo-img">
                <img src={item.image} alt="Combos Focus" />
              </div>
              <div className="combo-link">
                <a
                  style={{ backgroundColor: item.color, color: item.font }}
                  className="combo-contact"
                  href="https://api.whatsapp.com/send?phone=5491127479516"
                  target="_blank"
                >
                  Contratálo ahora
                </a>
              </div>
            </div>
          ))}
        </Slider>
        <div className="arrow-combo next-arrow-combo">
          <button onClick={next}>
            {" "}
            <img src={rightarrow} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Combo;
