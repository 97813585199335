import CarrouselBrand from "./CarrouselBrand";
import CardSliderActiones from "./CardSliderActions";
import Services from "./Services/Services";
import Performance from "./Performance";
import Testimonials from "./Testimonials";
import TeamWork from "./TeamWork";
import WhoWeAre from "./WhoWeAre";
import ResumeUs from "./ResumeUs";
import Benefits from "./Benefits";
import Investment from "./Investment";
import OurWork from "./OurWork";
import Collaborators from "./Collaborators";
import Combo from "./Combo";
import CalendlySection from "./CalendlySection";
import FocusMethod from "./FocusMethod";
import whatsapp from "../Assets/sticky-wp.png";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Main = () => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
    });
  }, []);
  return (
    <>
      <div className="main-principal">
        <div className="whatsapp-opener">
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5491127479516"
          >
            {" "}
            <img src={whatsapp} alt="" />
          </a>
        </div>

        <ResumeUs />
        <CarrouselBrand />
      </div>
      <div className="main-middle">
        <WhoWeAre />
        <Services />
        <FocusMethod />
        <CardSliderActiones />
        <Performance />
        <Testimonials />
        <TeamWork />
        <Benefits />
        <Investment />
        <OurWork />
        <Collaborators />
        <Combo />
        <CalendlySection />
      </div>
    </>
  );
};

export default Main;
