import React, { Component, useState } from "react";
import Slider from "react-slick";
import brand1 from "../Assets/Brands/1.png";
import brand2 from "../Assets/Brands/2.png";
import brand3 from "../Assets/Brands/3.png";
import brand4 from "../Assets/Brands/4.png";
import brand5 from "../Assets/Brands/5.png";
import brand6 from "../Assets/Brands/6.png";
import brand7 from "../Assets/Brands/7.png";
import brand8 from "../Assets/Brands/8.png";
import brand9 from "../Assets/Brands/9.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      useTransform: false,
      dots: false,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 0,
      pauseOnFocus: false,
      pauseOnHover: false,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        /* {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          }, 
        },*/
      ],
    };

    const brands = [
      brand1,
      brand2,
      brand3,
      brand4,
      brand5,
      brand6,
      brand7,
      brand8,
      brand9,
    ];

    return (
      <div
        data-aos="fade-in"
        data-aos-duration="3000"
        className="container-brand"
      >
        <Slider {...settings}>
          {brands.map((item, index) => (
            <div key={index}>
              <h3 className="brand">
                <img src={item} alt="" />
              </h3>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
