import leftarrow from "../Assets/left-green.svg";
import rightarrow from "../Assets/right-green.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import React from "react";

const Testimonials = () => {
  let sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          /*  centerMode: true, */
          centerPadding: "20%",
        },
      },
    ],
  };

  const testimonialCards = [
    {
      name: "Ignacio Barnetche",
      job: "Director, Manuel San Martin S.A.",
      testimony:
        '"Desde el día uno FOCUS se comprometió en desarrollar la mejor propuesta para cubrir nuestras necesidades y solucionar nuestra problemática."',
      color: "#b9e0fc",
    },
    {
      name: "Paloma Mayor Lupo",
      job: "RRHH, Newsan",
      testimony:
        '"Trabajar con su equipo es muy fácil! Siempre están dispuestos a brindar asistencia, garantizando un proceso fluído y sin complicaciones."',
      color: "#c7ede8",
    },
    {
      name: "Franco Strigaro",
      job: "Director, Expand your Limits",
      testimony:
        '"Sus servicios tienen una energía única y se adaptaron completamente a nuestras necesidades."',
      color: "#c3cefb",
    },
    {
      name: "Ayelen Panasiuk",
      job: "RRHH, Bonda",
      testimony:
        ' "El contenido de las actividades es excelente y sobre todo muy didáctico. Las estrategias que utilizan facilita mucho la conexión con el equipo. "',
      color: "#c7ede8",
    },
  ];

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <div
      data-aos="fade-in"
      data-aos-duration="1500"
      className="testimonial-slider-container wrapper"
    >
      <div className=" testimonial-slider-details wrapper">
        <div className=" control-testimonial-container">
          <div>
            <p>Tu aliado en el bienestar corporativo</p>
            <h1>
              Empresas líderes <br /> confían en el <br /> Método FOCUS
            </h1>
          </div>

          <div>
            <button onClick={previous}>
              {" "}
              <img src={leftarrow} alt="" />
            </button>

            <button onClick={next}>
              {" "}
              <img src={rightarrow} alt="" />
            </button>
          </div>
        </div>
        <div className="testimonial-cards-details">
          <div className="testimonial-fade"></div>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {testimonialCards.map((item) => (
              <div>
                <div
                  style={{ backgroundColor: item.color }}
                  className="testimonial-cards-info"
                >
                  <div className="testimonial-cards">
                    {" "}
                    <p>{item.testimony}</p>
                    <div>
                      <h1>{item.name}</h1>
                      <h2>{item.job}</h2>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
