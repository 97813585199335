import { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import React from "react";
import starfilled from "../Assets/ star-filled.svg";
import starempty from "../Assets/star-empty.svg";

import leftarrow from "../Assets/left-green.svg";
import rightarrow from "../Assets/right-green.svg";

const Collaborators = () => {
  let sliderRef = useRef();

  const [current, setCurrent] = useState(0);

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    infinite: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    afterChange: (a) => {
      setCurrent(a);
    },
  };

  const collaboratorsCards = [
    {
      name: "Juan Martín",
      testimony:
        "Los workshops de ergonomía me ayudaron mucho! Antes sufría de dolores constantes en la espalda, pero aprendí cómo ajustar mi espacio de trabajo y mejorar mi postura. Me siento mucho más cómodo y productivo.",
      starfilled: 4,
      starempty: 1,
    },
    {
      name: "Catalina",
      testimony:
        "La inclusión de programas de bienestar me cambió la dinámica en la oficina. Estoy más motivada y enfocada, con más atención en mi salud mental y física.",
      starfilled: 5,
      starempty: 0,
    },

    {
      name: "Adriana",
      testimony:
        "Los masajes en la oficina son una bendición. Trabajar frente a la computadora solía dejarme muy tensa, pero desde que implementaron esto noto una gran mejoría. ¡Cuando toca el día nos ponemos muy contentos!.",
      starfilled: 3,
      starempty: 2,
    },
    {
      name: "Sofía",
      testimony:
        "Lo que más me gustó fueron las acciones de coaching y mindfulness. No me daba cuenta cuanto necesitaba este tipo de actividades. De a poco voy sumando herramientas para gestionar el stress y estar más creativa.",
      starfilled: 5,
      starempty: 0,
    },
    {
      name: "Rodrigo",
      testimony:
        "Sentimos un cambio real en el ambiente laboral y la salud mental de todos. En los workshop nos dan mucha info interesante y práctica ¡Buena decisión de los líderes acceder a estas actividades!.",
      starfilled: 4,
      starempty: 1,
    },
    {
      name: "Antonella",
      testimony:
        "Agradezco mucho que mi empresa se preocupe por nuestro bienestar general. Con los servicios que nos ofrece FOCUS tenemos la posibilidad de cuidarnos a nivel físico, mental y emocional.",
      starfilled: 4,
      starempty: 1,
    },
  ];

  const mapNum = (num, in_min, in_max, out_min, out_max) => {
    return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  };

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <div className="wrapper-small collaborators-container">
      <div className="collaborators-title">
        <h1>Unite a +395 colaboradores!</h1>

        <div>
          <button onClick={previous}>
            {" "}
            <img src={leftarrow} alt="" />
          </button>

          <button onClick={next}>
            {" "}
            <img src={rightarrow} alt="" />
          </button>
        </div>
      </div>
      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        {collaboratorsCards.map((item) => (
          <div
            data-aos="fade-in"
            data-aos-duration="1500"
            className="collaborators-info"
          >
            <div className="division-collabs">
              <p>{item.testimony}</p>

              <div className="collaborators-punctuation">
                <h1>{item.name}</h1>
                <div>
                  {Array(item.starfilled)
                    .fill()
                    .map((x) => (
                      <img src={starfilled} />
                    ))}
                  {Array(item.starempty)
                    .fill()
                    .map((x) => (
                      <img src={starempty} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="collab-progress">
        <div
          style={{ width: `${Math.max(mapNum(current, 0, 5, 0, 100), 10)}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Collaborators;
