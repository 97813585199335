import React, { Component, useState } from "react";
import Slider from "react-slick";
import brand1 from "../Assets/calendlyWindow/1.png";
import brand2 from "../Assets/calendlyWindow/2.png";
import brand3 from "../Assets/calendlyWindow/3.png";
import brand4 from "../Assets/calendlyWindow/4.png";
import brand5 from "../Assets/calendlyWindow/5.png";
import brand6 from "../Assets/calendlyWindow/6.png";
import brand7 from "../Assets/calendlyWindow/7.png";
import brand8 from "../Assets/calendlyWindow/8.png";
import brand9 from "../Assets/calendlyWindow/9.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1500,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        /* {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          }, 
        },*/
      ],
    };

    const brands = [
      brand1,
      brand2,
      brand3,
      brand4,
      brand5,
      brand6,
      brand7,
      brand8,
      brand9,
    ];

    return (
      <div className="container-brand container-complementary-brand">
        <Slider {...settings}>
          {brands.map((item, index) => (
            <div key={index}>
              <h3 className="brand">
                <img src={item} alt="" />
              </h3>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
