import checklist from "../Assets/Hero_check.png";
import home1 from "../Assets/home-1.png";
import { Link } from "react-router-dom";

const ResumeUs = () => {
  return (
    <div data-aos="fade-in" data-aos-duration="3000" className="main-container">
      <div className="main-details wrapper-small">
        <div>
          <h1>
            Bienestar físico y <br />
            mental empresarial
          </h1>
          <h2>
            Potenciamos el rendimiento de tu empresa generando espacios de
            trabajo saludables.
          </h2>

          <div className="list-container">
            <ul>
              <li>
                {" "}
                <img src={checklist} alt="" />
                Profesionales de la salud
              </li>
              <li>
                {" "}
                <img src={checklist} alt="" />
                Horarios flexibles
              </li>
            </ul>
          </div>
          <div className="list-container">
            <ul>
              <li>
                {" "}
                <img src={checklist} alt="" />
                Múltiples formatos
              </li>
              <li>
                {" "}
                <img src={checklist} alt="" />
                Resultados medibles
              </li>
            </ul>
          </div>
          <div className="services-home">
            <Link className="meeting-button" to="/calendly">
              Agendar una reunión
            </Link>

            <a className="services-button" href="#services-scroll">
              Nuestros servicios
            </a>
          </div>
        </div>
        <div>
          <img className="home-principal-image" src={home1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ResumeUs;
