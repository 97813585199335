import emotional1 from "../../Assets/Servicios/salud emocional/1.png";
import emotional2 from "../../Assets/Servicios/salud emocional/2.png";
import emotional3 from "../../Assets/Servicios/salud emocional/3.png";
import emotional4 from "../../Assets/Servicios/salud emocional/4.png";
import physic1 from "../../Assets/Servicios/salud fisica/1.png";
import physic2 from "../../Assets/Servicios/salud fisica/2.png";
import physic3 from "../../Assets/Servicios/salud fisica/3.png";
import physic4 from "../../Assets/Servicios/salud fisica/4.png";
import nutritional1 from "../../Assets/Servicios/salud nutricional/1.png";
import nutritional2 from "../../Assets/Servicios/salud nutricional/2.png";
import nutritional3 from "../../Assets/Servicios/salud nutricional/3.png";
import social1 from "../../Assets/Servicios/salud social/1.png";
import social2 from "../../Assets/Servicios/salud social/2.png";
import social3 from "../../Assets/Servicios/salud social/3.png";

const physicalHealth = [
  {
    categoryId: 1,
    image: physic1,
    title: "Pausas activas terapéuticas",
    description:
      " Clases de ejercicios livianos y terapéuticos que previenen lesiones y alivian tensiones musculares. A su vez brindamos pautas para la salud postural.",
  },
  {
    categoryId: 1,
    image: physic2,
    title: "Masajes in-house",
    description:
      "Masajes dentro del entorno laboral. Sesiones breves de entre 15 a 30 minutos diseñados para reducir el estrés, eliminar tensiones y promover el bienestar físico y mental.",
  },
  {
    categoryId: 1,
    image: physic3,
    title: "Yoga de escritorio",
    description:
      "Práctica adaptada al entorno laboral que combina ejercicios simples de yoga y técnicas de relajación.",
  },
  {
    categoryId: 1,
    image: physic4,
    title: "Programa Ergowell",
    description:
      "Workshops donde brindamos información teórico/práctica sobre la correcta configuración ergonómica y hábitos posturales en el entorno laboral.",
  },
];

const nutritionalHealth = [
  {
    categoryId: 2,
    image: nutritional1,
    title: "Sesiones personalizadas",
    description:
      "Brindamos un espacio seguro y confidencial para explorar hábitos alimenticios, establecer metas y recibir recomendaciones nutricionales.",
  },
  {
    categoryId: 2,
    image: nutritional2,
    title: "Programa Nutriwell",
    description:
      "Serie de talleres teóricos/prácticos sobre hábitos alimenticios saludables para mejorar el rendimiento laboral y prevenir transtornos de alimentación.",
  },
  {
    categoryId: 2,
    image: nutritional3,
    title: "Viandas y snacks saludables",
    description:
      "Ofrecemos viandas de alimentos equilibrados, nutritivos y variados.",
  },
];

const emotionalHealth = [
  {
    categoryId: 3,
    image: emotional1,
    title: "Coaching empresarial",
    description:
      "Se centra en potenciar el autoconocimiento empoderando la toma de decisiones, identificando fortalezas y oportunidades con metas específicas.",
  },
  {
    categoryId: 3,
    image: emotional2,
    title: "Encuentros de equidad y género",
    description:
      "Conversaciones en donde se abordan temas de inclusión, diversidad y otros desafíos que afectan a las personas en diferentes ámbitos.",
  },
  {
    categoryId: 3,
    image: emotional3,
    title: "Programa +Bienestar",
    description:
      "Sesiones para cultivar la atención plena centradas en la conciencia del presente, meditación y respiración fomentando un mayor bienestar emocional.",
  },
  {
    categoryId: 3,
    image: emotional4,
    title: "Sesiones personalizadas",
    description:
      "Ofrecemos un espacio seguro donde explorar las emociones de los colaboradores y recibir orientación adaptada a su vida y preferencias.",
  },
];

const socialHealth = [
  {
    categoryId: 4,
    image: social1,
    title: "Team experience",
    description:
      "Eventos para fortalecer la cohesión, la socialización y el espíritu de equipo. Buscamos crear momentos significativos que fortalezcan las relaciones entre los colaboradores.",
  },
  {
    categoryId: 4,
    image: social2,
    title: "CoWork",
    description:
      "En FOCUS buscamos generar comunidad para trabajadores remotos. Tendrán la posibilidad de compartir espacios equipados con todo lo necesario y a su vez fortalecer vínculos.",
  },
  {
    categoryId: 4,
    image: social3,
    title: "Retiros de reconexión integral",
    description:
      "Encuentros fuera del entorno laboral en donde se explorarán temas esenciales para el desarrollo personal y bienestar integral.",
  },
];

export default [
  {
    id: 1,
    content: physicalHealth,
    label: "Salud física",
  },
  {
    id: 2,
    content: nutritionalHealth,
    label: "Salud nutricional",
  },
  {
    id: 3,
    content: emotionalHealth,
    label: "Salud emocional",
  },
  {
    id: 4,
    content: socialHealth,
    label: "Salud social",
  },
];
