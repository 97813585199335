import InvestmentVector from "../Assets/investment.svg";
import { Link } from "react-router-dom";
import React from "react";

const Investment = () => {
  return (
    <div className="wrapper-mobile">
      <div
        data-aos="fade-left"
        data-aos-duration="1500"
        className="investment-container wrapper"
      >
        <div className="details-investment">
          <h1>
            Una inversión con propósito,
            <br />
            alto impacto y{" "}
            <span className="investment-text">
              <i>bajo&nbsp;costo.</i> <img src={InvestmentVector} alt="" />
            </span>{" "}
          </h1>
          <h2>Convertite en una empresa saludable con nuestro método.</h2>
          <Link to="/calendly">Agendar una reunión</Link>
        </div>
      </div>
    </div>
  );
};

export default Investment;
