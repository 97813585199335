const PancakeMenu = ({ handleClick, classname }) => {
  return (
    <>
      <button onClick={handleClick} className={classname}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </>
  );
};

export default PancakeMenu;
