import React from "react";

const FocusMethod = () => {
  const methodCards = [
    {
      id: 1,
      tittle: "Primer contacto",
      text: "Tenemos una primer entrevista con tu equipo para conocer que está pasando en tu empresa.",
      text2: "",
    },
    {
      id: 2,
      tittle: "Informe",
      text: "Identificamos las necesidades para planificar de manera efectiva nuestro trabajo.",
      text2: "¿Cuál es la problemática?",
      text3: "¿Cuál es el objetivo?",
    },
    {
      id: 3,
      tittle: "Solución",
      text: "Diseñamos la mejor propuesta customizada y adaptada a las necesidades específicas de tu empresa.",
      text2: "",
    },
    {
      id: 4,
      tittle: "Reevaluación",
      text: "Revisamos nuestro trabajo para valorar aciertos y seguir mejorando.",
      text2: "",
    },
  ];

  return (
    <div
      id="method-scroll"
      data-aos="fade-in"
      data-aos-duration="1500"
      className="wrapper method-container"
    >
      <div className="method-info">
        {" "}
        <p className="method-info-p">¿Cómo lo hacemos?</p>
        <h1>Método FOCUS</h1>
        <p className="method-info-details-p">
          Podes elegir el formato de trabajo que mejor se adapte a tus
          necesidades.
        </p>
        <div className="method-mode">
          <p>Virtual</p>
          <p>Presencial</p>
          <p>Híbrido</p>
        </div>
      </div>
      <div className="method-details wrapper">
        <div className="method-steps">
          {[1, 2, 3, 4].map((i) => (
            <div className={`method-step method-step-${i}`} key={i}>
              <span>{i}</span>
            </div>
          ))}
          <div className="method-line">
            <div className="method-line-main"></div>
          </div>
        </div>
        <div className="method-steps-info">
          <div className="method-steps-info-line">
            <div className="method-steps-info-line-main"></div>
          </div>
          {methodCards.map((item, index) => (
            <div key={index} className="method-steps-info-step">
              <span
                className={`methods-step-info-mobile method-step-${index + 1}`}
                key={index}
              >
                {index + 1}
              </span>
              <div>
                <h1>{item.tittle}</h1>
                <p>{item.text}</p>
                <p className="questions-method questions-method1">
                  {item.text2}
                </p>
                <p className="questions-method">{item.text3}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FocusMethod;
