import logofocus from "../Assets/logo.png";
import whatsapp from "../Assets/Footer/whatsapp.svg";
import email from "../Assets/Footer/email.svg";
import linkedin from "../Assets/Footer/linkedin.svg";
import instagram from "../Assets/Footer/instagram.svg";
import Contact from "./Contact";

const Footer = () => {
  const fecha = new Date();
  const añoActual = fecha.getFullYear();
  return (
    <div className="footer-container">
      <div className="top-footer wrapper">
        <div>
          <a href="#">
            {" "}
            <img
              className="logo-principal"
              src={logofocus}
              alt="Logo principal de Focus"
            />
          </a>

          <p className="footer-title">
            Bienestar físico y mental para empresas
          </p>
          <div className="social-media-container">
            <a
              href="https://api.whatsapp.com/send?phone=5491127479516"
              target="_blank"
            >
              <img src={whatsapp} alt="Icono de WhatsApp" />
            </a>
            <a target="_blank" href="mailto:info@focuswellnesscorp.com">
              <img src={email} alt="Icono de Email" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/focus-wellnesscorp/"
            >
              <img src={linkedin} alt="Icono de Linkedin" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/focus.wellnesscorp/"
            >
              <img src={instagram} alt="Icono de Instagram" />
            </a>
          </div>
        </div>
        <Contact />
      </div>
      <div className="bottom-footer wrapper">
        <div className="bottom-footer-rights">
          <p>© FOCUS - {añoActual}. All rights reserved</p>

          <div>
            <p>Website: </p>
            <a
              href="https://api.whatsapp.com/send?phone=541144079115"
              target="_blank"
            >
              Selene Pavlotsky
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
