import { useState } from "react";
import PancakeMenu from "./PancakeMenu";
import logo1 from "../Assets/logo.png";
import logo2 from "../Assets/logo2.svg";
import NavBar from "./NavBar";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Header = ({ isNewPage }) => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
    });
  }, []);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={`header-container ${isNewPage ? "new" : ""} `}>
        <div
          data-aos="fade-in"
          data-aos-duration="3000"
          className="bottom-header-details"
        >
          <a href={isNewPage ? "/#" : "#"}>
            <img
              className="logo-principal"
              src={isNewPage ? logo2 : logo1}
              alt="Logo principal de Focus"
            />
          </a>

          <PancakeMenu
            classname={`hamburguer-menu ${open ? "open" : ""}`}
            handleClick={handleClick}
          />
          <NavBar isNewPage={isNewPage} setOpen={setOpen} open={open} />
          <div className="contact-header-fixed">
            <a
              className="contact-header"
              href="https://api.whatsapp.com/send?phone=5491127479516"
              target="_blank"
            >
              Contactanos
            </a>
            <span>Contactanos</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
